import { useContext, useState, useEffect } from 'react'
import { Leading, Signature, Sort2 } from '../assets/icons'
import { MainLayout } from './'
import { RegisteredMailSidebar } from '../components/misc'
import { RegisteredMailContext } from '../context'
import { Select, Option, DateInput, Multiselect } from '../components/ui_new'
import { AR24_STATUS_LABELS } from '../constants'
import { statusLabelForAr24Sender } from '../utils'
import { UserContext, SignatureContext } from '../context'
import { MoreVert, Person } from '@material-ui/icons'
import { CalendarToday, Domain, ChevronRight, ChevronLeft, RadioButtonChecked } from '@material-ui/icons'
import moment from 'moment';
import { CalendarMonthOutlined, PersonOutline, Refresh } from '@mui/icons-material'


const RegistredMailLayout = ({ children, onSort, activeSort, activeOrder, onRefresh, refreshingStatus, handleStatusRefreshClick, ...props }) => {
  const { user } = useContext(UserContext)
  const { ar24MailFilter, setAr24MailFilter, ar24Sender, ar24SenderEvents } = useContext(RegisteredMailContext)
  const [dateAfter, setDateAfter] = useState(ar24MailFilter.date_after)
  const [dateBefore, setDateBefore] = useState(ar24MailFilter.date_before)
  const [authors, setAuthors] = useState([])

  const [showFilters, setShowFilters] = useState(false)
  const [displayFilter, setDisplayFilter] = useState(-1);

  useEffect(() => {
    if (ar24SenderEvents) {
      console.log("ar24SenderEvents", ar24SenderEvents)
      // TODO: need improvement
      setAuthors(ar24SenderEvents.map(doc => doc.created_by.admin_name))
    }
  }, [ar24SenderEvents]);

  const handleFilterChange = (key) => (value) => {
    console.log("handleFilterChange", key, value)
    setAr24MailFilter({
      ...ar24MailFilter,
      [key]: value
    })
  }
  const handleAuthorChange = (value) => {
    // TODO: need improvement
    setAr24MailFilter(prev => ({
      ...prev,
      author: value
    }))
  }

  // On date change - range
  const handleDateRangeChange = (date) => {
    const rangeStartDate = date[0]
    const rangeEndDate = date[1]
    if (!rangeStartDate || !rangeEndDate) {
      return;
    }
    setDateAfter(rangeStartDate)
    setDateBefore(rangeEndDate)
    setAr24MailFilter({
      ...ar24MailFilter,
      date_after: rangeStartDate,
      date_before: rangeEndDate
    })
  }

  const handleDateRangeClear = () => {
    setDateAfter(null)
    setDateBefore(null)
    setAr24MailFilter({
      ...ar24MailFilter,
      date_after: null,
      date_before: null
    })
  }

  const displayDate = () => {
    if (dateAfter && dateBefore) {
      return moment(dateAfter).format('MM/YYYY') + ' - ' + moment(dateBefore).format('MM/YYYY');
    }
    return '-'
  }

  const displayOwner = (owner) => {
    const ownerObj = (user.agencies ? user.agencies : [user.agency]).find((item) => item.manufacturers_id === owner)
    if (ownerObj) {
      return `${ownerObj.manufacturers_name}`
    }
    return 'Toutes les agences'
  }
  const displayAuthor = (author) => {
    const authorObj = authors.find((item) => item.id === author);

    if (authorObj) {
      return `${authorObj}`
    }
    return 'Tous'
  }

  const displayStatus = (statusArr) => {
    if (AR24_STATUS_LABELS?.[statusArr?.[0]]) {
      return statusArr.map((statusItem, index) => {
        return AR24_STATUS_LABELS[statusItem] + (index !== statusArr.length && ', ');
      });
    }
    return 'Tous les statuts'
  }
  return (
    <MainLayout className="registered-mail" pageTitle="Lettres recommandées" hideTitleOnDesktop={true} showSearch={true} searchIn="registeredMail">
      <div className="registered-mail-content">
        <RegisteredMailSidebar {...props} />
        <div className="registered-mail-content__header">
          {props.sidebarSection === 'tracking' && (
            <div className='filters-section-parent'>
              <button className='filters-section-toggle' onClick={() => setShowFilters(!showFilters)}>
                <MoreVert />
              </button>
              <section className={`filters-section w-auto ${showFilters && 'show'}`}>
                {displayFilter !== -1 && <button onClick={() => { setDisplayFilter(-1) }} className='back-btn'>
                  <ChevronLeft /> Retour
                </button>}
                {displayFilter === -1 && <ul className='mobile-filters-list'>
                  <li onClick={() => { setDisplayFilter(0) }}>
                    <span><CalendarMonthOutlined /> {displayDate()} </span>
                    <ChevronRight />
                  </li>
                  <li onClick={() => { setDisplayFilter(1) }}>
                    <span><Domain /> {displayOwner(ar24MailFilter.owner)}</span>
                    <ChevronRight />
                  </li>
                  <li onClick={() => { setDisplayFilter(2) }}>
                    <span><RadioButtonChecked /> {displayStatus(ar24MailFilter.status)}</span>
                    <ChevronRight />
                  </li>
                  <li onClick={() => { setDisplayFilter(3) }}>
                    <span><PersonOutline fontSize='medium' /> {displayAuthor(ar24MailFilter.author)}</span>
                    <ChevronRight />
                  </li>
                </ul>}
                <div className={`filters-section__item ${displayFilter === 0 ? 'show' : ''}`}>
                  <DateInput
                    selectsRange={true}
                    placeholder="Créé après - Créé avant"
                    onChange={handleDateRangeChange}
                    onChangeRaw={(e) => e.preventDefault()}
                    isClearable={dateAfter && dateBefore}
                    onClearableClick={handleDateRangeClear}
                    selected={[dateAfter, dateBefore]}
                    minDate={dateAfter}
                    maxDate={dateBefore}
                    expandable={true}
                  />
                </div>
                {/* TODO add recipient filter field */}
                <div className={`filters-section__item ${displayFilter === 1 ? 'show' : ''}`}>
                  <Select label="" onChange={handleFilterChange('agence')} selected={ar24MailFilter.agence} className={'!h-10'}>
                    <Option value="all">Toutes les agences</Option>
                    {/* {Object.entries(recipients).map((status, index) => (
                <Option key={index} value={status[0]}>{status[1]}</Option>
              ))} */}
                  </Select>
                </div>
                <div className={`filters-section__item ${displayFilter === 2 ? 'show' : ''}`}>
                  <Multiselect label="" valueToClear='all' onChange={handleFilterChange('status')} className={'!h-10'} placeholder="Tous les statuts" values={ar24MailFilter.status} options={[
                    { value: 'all', label: 'Tous les statuts' },
                    ...Object.entries(AR24_STATUS_LABELS).map(status => ({ value: status[0], label: status[1] }))
                  ]} />
                </div>
                <div className={`filters-section__item ${displayFilter === 3 ? 'show' : ''}`}>
                  <div className="filters-section__item">
                    <Select label="Envoyé par " onChange={handleAuthorChange} selected={ar24MailFilter.author} className={'!h-10'}>
                      <Option value="all">Tous</Option>
                      {authors.map((author, index) => (
                        <Option key={index} value={author.id}>
                          {author}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </section>
              <div className='flex gap-2'>
                <button className="button ml-2 button--with-icon button--primary-light rounded-sm text-nowrap" onClick={onRefresh}>
                  <Refresh />Actualiser les statuts
                </button>

                <div className="filters-section-sort" onClick={() => onSort('date')}>
                  <div className="flex lg:px-3 h-8 items-center">
                    <Sort2 />
                    <span className='sort-label'>{activeOrder === 'desc' ? `+ récent/+ ancien` : `+ ancien/+ récent`}</span>
                  </div>
                </div>

              </div>

            </div>
          )}

          {props.sidebarSection === 'sender' && (
            <div className='filters-section-parent'>
              <button className='filters-section-toggle' onClick={() => setShowFilters(!showFilters)}>
                <MoreVert />
              </button>
              {ar24Sender && <div className='flex flex-wrap align-center gap-2 ml-auto'>
                <div className='flex flex-row align-center justify-start gap-2'>
                  <span className='sender-status-title'>Statut du compte : </span>
                  <div className='flex flex-row align-center pr-2'>
                    <div className={`status-value ${ar24Sender?.status}`}>{statusLabelForAr24Sender(ar24Sender) || ar24Sender?.status}</div>
                    <button className={`icon-btn icon-btn--transparent refresh-status ${refreshingStatus ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Refresh fontSize='small' /></button>
                  </div>
                </div>
                <button className='button action-btn ml-4' onClick={props.onModifySender}>
                  <span>
                    <Leading />
                    Compte expéditeur AR24
                  </span>
                </button>
              </div>}
            </div>
          )}
        </div>
        <div className="registered-mail-content__main">
          {children}
        </div>
      </div>
    </MainLayout>
  )
}

export default RegistredMailLayout