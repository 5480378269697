import React from 'react'
import { InfoCircle } from '../../../../assets/icons'
import { CustomTooltip, Input } from '../../../ui_new'

const SenderForm = ({ data = {}, onDataChange = () => { }, errors = {}, emailTooltip = '', emailDisabled = false, editable }) => {

  return (
    <div>
      <div className={`form form--group ${errors['company'] ? 'invalid' : ''}`}>
        <Input label="Dénomination sociale"
          disabled={!editable}
          className="field-name mb-6"
          placeholder='Dénomination sociale'
          name='company'
          value={data.company}
          error={errors['company'] ? errors['company'] : ''}
          onChange={onDataChange} />
      </div>
      <div className='flex flex-row mobile-flex-wrap justify-between gap-4'>
        {/* <div className={`form--group flex-grow ${errors['gender'] ? 'invalid' : ''}`}>
          <label className='form-label'>Civilité</label>
          <select
            className='form-input'
            type='select'
            placeholder='Civilité'
            name='gender'
            onChange={onDataChange}
            disabled={!editable}
            value={data.gender}
          >
            <option value='' disabled>Civilité</option>
            <option value='f'>Madame</option>
            <option value='m'>Monsieur</option>
          </select>
          <Input
            className='form-input'
            type='text'
            placeholder='Civilité'
            name='gender'
            value={data.gender}
            onChange={onDataChange}
            error={errors['gender'] ? errors['gender'] : ''}
            disabled={!editable}
          />
        </div> */}
        <div className={`w-half flex gap-4`}>
          <div className={`form--group flex-grow w-half  ${errors['firstname'] ? 'invalid' : ''}`}>
            <Input label="Prénom"
              disabled={!editable}
              className="field-name mb-6"
              placeholder='Prénom'
              name='firstname'
              value={data.firstname}
              error={errors['firstname'] ? errors['firstname'] : ''}
              onChange={onDataChange} />
          </div>
          <div className={`form--group flex-grow w-half ${errors['lastname'] ? 'invalid' : ''}`}>
            <Input label="Nom"
              disabled={!editable}
              className="field-name mb-6"
              placeholder='Nom'
              name='lastname'
              value={data.lastname}
              error={errors['lastname'] ? errors['lastname'] : ''}
              onChange={onDataChange} />
          </div>
        </div>

        <div className={`form--group flex-grow w-half ${errors['email'] ? 'invalid' : ''}`}>
          <div className='form-wrapper'>
            <Input label="Email"
              disabled={!editable || emailDisabled}
              className="field-name mb-6"
              placeholder='Email'
              name='email'
              value={data.email}
              error={errors['email'] ? errors['email'] : ''}
              onChange={onDataChange} />
            {emailTooltip && <div className='input-tooltip align-end'>
              <CustomTooltip content={emailTooltip}>
                <div className="info"> <InfoCircle /></div>
              </CustomTooltip>
            </div>}
          </div>
        </div>
      </div>
      <div className='flex flex-row mobile-flex-wrap justify-between gap-4'>
        <div className={`form--group w-half ${errors['address1'] ? 'invalid' : ''}`}>
          <Input label="Rue"
            disabled={!editable}
            className="field-name mb-6"
            placeholder='Rue'
            name='address1'
            value={data.address1}
            error={errors['address1'] ? errors['address1'] : ''}
            onChange={onDataChange} />
        </div>
        <div className={`w-half flex gap-4`}>
          <div className={`form--group flex-grow w-half ${errors['zipcode'] ? 'invalid' : ''}`}>
            <Input label="Code postal"
              disabled={!editable}
              className="field-name mb-6"
              placeholder='Code postal'
              name='zipcode'
              value={data.zipcode}
              error={errors['zipcode'] ? errors['zipcode'] : ''}
              onChange={onDataChange} />
          </div>
          <div className={`form--group flex-grow w-half ${errors['city'] ? 'invalid' : ''}`}>
            <Input label="Ville"
              disabled={!editable}
              className="field-name mb-6"
              placeholder='Ville'
              name='city'
              value={data.city}
              error={errors['city'] ? errors['city'] : ''}
              onChange={onDataChange} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default SenderForm