import { useState, useEffect, useRef, memo } from 'react'

import { availableOn, NUMBER_FORMATTERS } from '../../utils'
import { EMPTY_LINES } from '../../constants'
import { User, AddressCard } from '../../assets/icons'
import { CustomTooltip, Input } from '../ui_new'

const VariableTextInput = ({ fieldVariable, handle, repeatableIds, repetitionIndices, val, linesAdded, isHandleLocked, onInputChange, onAgencyModalOpen, onNotariesModalOpen }) => {
  const { variable, tooltip, type, idx} = fieldVariable
  let classes = "tooltip-wrapper doc-variable-el"
  if(availableOn(['development']) && (variable === 'manufacturers_name' || variable === 'co_na_customers_lastname')) {
    classes += ' doc-variable-el--with-icon'
  }

  const formattedValue = (value) => {
    if(type === 'number') {
      value = parseFloat(value)
    }
    let val = value
    const formatters = handle.substring(1, handle.length - 1).split(':').slice(1).filter(f => NUMBER_FORMATTERS[f])
    
    for(let i = 0; i < formatters.length; i++) {
      const f = formatters[i]
      if(NUMBER_FORMATTERS[f]) {
        val = NUMBER_FORMATTERS[f](val)

      }
    }
    return val
  }

  const isHandleFormatterLocked = () => {
    const formatters = handle.substring(1, handle.length - 1).split(':').slice(1)
    for(let f of formatters) {
      if(NUMBER_FORMATTERS[f]) {
        return true
      }
    }
    return false
  }

  return (
    <div className={classes} data-id={`${idx}${repeatableIds?.length > 0 ? `-${repeatableIds.map((repId, repIdIndex) => `${repId}-${repetitionIndices?.[repIdIndex]}`).join('-')}` : ''}`}>
      <div style={{padding: '5px 0'}}>
        <TextInput value={formattedValue(val)} variable={variable} locked={isHandleLocked(handle) || isHandleFormatterLocked()} type={(type === 'number' && typeof formattedValue(val) === 'string') ? 'string' : type} onInputChange={(v, val) => onInputChange(v, val, repeatableIds, repetitionIndices)} tooltip={tooltip} linesAdded={linesAdded} />
      </div>
      {availableOn(['development']) && variable === 'manufacturers_name' && (
        <CustomTooltip content="Changer les coordonnées de l'agence">
          <span className="icon" onClick={onAgencyModalOpen}><AddressCard /></span>
        </CustomTooltip>
      )}
      {availableOn(['development']) && variable === 'co_na_customers_lastname' && (
        <CustomTooltip content="Annuaire des notaires">
          <span className="icon" onClick={onNotariesModalOpen}><User /></span>
        </CustomTooltip>
      )}
    </div>
  )
}

// Text input component
export const TextInput = ({ value, variable, locked, onInputChange, type, tooltip, linesAdded = false, onBlur }) => {
  if(typeof value !== 'string' && value !== null && value !== undefined) {
    value = String(value)
  }
  const spanEl = useRef()
  let add = 0;
  let textInputClass = 'doc-variable-el__span'
  if(type === 'number') {
    textInputClass += ' doc-variable-el__span--number'
    add = 56
  }
  const [style, setStyle] = useState(value 
    ? { width: `${Math.max(((spanEl.current?.offsetWidth || 0) + add), 140)}px` }
    : { maxWidth: '100% !important' })

  useEffect(() => {
    let add =  type === 'number' ? 56 : 0;

    setStyle(value 
      ? { width: `${Math.max(((spanEl.current?.offsetWidth || 0) + add), 140)}px` }
      : { maxWidth: '100% !important' }
    )
  }, [value, type, tooltip])
  
  return (
    <>
      <span ref={spanEl} className={textInputClass}>{value}</span>
      <Input 
        disabled={locked} 
        id={variable} 
        value={value || ''} 
        type={type} 
        editMode 
        onChange={ (e) => onInputChange(variable, e.target.value) } 
        className={value ? 'not-empty' : ''} 
        tooltip={tooltip}
        inputStyle={style} 
        linesAdded={linesAdded}
        onBlur={onBlur}
        placeholder={linesAdded ? (type === 'number' ? EMPTY_LINES.number : EMPTY_LINES.string) : ''}
      /> 
    </>
  )
}

export default memo(VariableTextInput)