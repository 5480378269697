import { useState, useEffect } from 'react';
import { DateInput, Select, Option } from '../ui_new'
import { ESIGN_STATUS_FILTER_OPTIONS, SIGNATURE_STATUS_TRANLSATIONS, VIALINK_SIGNATURE_STATUS_FILTER_OPTIONS } from '../../constants'
import { isMlsPartner } from '../../utils'
import { useContext } from 'react'
import { UserContext, SignatureContext } from '../../context'
import { Redo, Sort2 } from '../../assets/icons';
import { FEATURE, isFeatureAuthorized, isFeatureEnabled, manufacturerProperty } from '../../utils'
import { MoreVert, Person } from '@material-ui/icons';
import { CalendarToday, Domain, ChevronRight, ChevronLeft, RadioButtonChecked } from '@material-ui/icons'
import moment from 'moment';
import { CalendarMonthOutlined, PersonOutline } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';



const SignaturesContentSidebar = ({ onSetSelectedFilters, selectedFilters, onSort, activeOrder, activeSort, onRefreshStatuses }) => {

  const { partner } = useContext(UserContext)
  const { signatures } = useContext(SignatureContext)

  const [dateAfter, setDateAfter] = useState(selectedFilters.date_after)
  const [dateBefore, setDateBefore] = useState(selectedFilters.date_before)
  const { user, userClaims } = useContext(UserContext)
  const [authors, setAuthors] = useState([]);
  const [showFilters, setShowFilters] = useState(false)
  const [displayFilter, setDisplayFilter] = useState(-1);
  const [showSortDropdown, setShowSortDropdown] = useState(false)


  useEffect(() => {
    if (signatures) {
      setAuthors(Object.values(signatures).filter(doc => doc.author).filter((doc, index, docs) => {
        return docs.findIndex(d => d.author.id === doc.author.id) === index
      }).map(doc => doc.author))
    }
  }, [signatures]);

  // On status change
  const handleStatusChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      status: value
    }))
  }
  // On date change - range
  const handleDateRangeChange = (date) => {
    const rangeStartDate = date[0]
    const rangeEndDate = date[1]
    if (!rangeStartDate || !rangeEndDate) {
      return;
    }
    setDateAfter(rangeStartDate)
    setDateBefore(rangeEndDate)
    onSetSelectedFilters(prev => ({
      ...prev,
      date_after: rangeStartDate,
      date_before: rangeEndDate
    }))
  }
  const handleOwnerChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      owner: value
    }))
  }

  const handleAuthorChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      author: value
    }))
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const displayDate = () => {
    if (dateAfter && dateBefore) {
      return moment(dateAfter).format('MM/YYYY') + ' - ' + moment(dateBefore).format('MM/YYYY');
    }
    return '-'
  }
  const displayOwner = (owner) => {
    const ownerObj = (user.agencies ? user.agencies : [user.agency]).find((item) => item.manufacturers_id === owner)
    if (ownerObj) {
      return `${ownerObj.manufacturers_name}`
    }
    return 'Toutes les agences'
  }
  const displayAuthor = (author) => {
    const authorObj = authors.find((item) => item.id === author)
    if (authorObj) {
      return `${authorObj.firstname} ${authorObj.lastname}`
    }
    return 'Tous'
  }

  const displayStatus = (status) => {
    if (SIGNATURE_STATUS_TRANLSATIONS[selectedFilters.status]) {
      return SIGNATURE_STATUS_TRANLSATIONS[selectedFilters.status]
    }
    return 'Tous les statuts'
  }

  const sortOptions = {
    'title': { label: 'Nom', asc: 'A > Z', desc: 'Z > A' },
    'meta.created': { label: `Date d'envoi`, asc: '+ ancienne', desc: '+ récente' },
    'sort_recipient_names': { label: 'Destinataires', asc: 'A > Z', desc: 'Z > A' },
    'sender.email': { label: 'Envoyé par', asc: 'A > Z', desc: 'Z > A' },
    'sort_status': { label: 'Statut', asc: 'A > Z', desc: 'Z > A' },
    'expires': { label: `Date d'expiration`, asc: '+ ancienne', desc: '+ récente' },
  };

  return (
    <div className="signatures-content__sidebar u-custom-scrollbar">
      <div className='filters-section-parent'>
        <button className='filters-section-toggle' onClick={toggleFilters}>
          <MoreVert />
        </button>
        <section className={`filters-section ${showFilters && 'show'}`}>
          {displayFilter !== -1 && <button onClick={() => { setDisplayFilter(-1) }} className='back-btn'>
            <ChevronLeft /> Retour
          </button>}
          {displayFilter === -1 && <ul className='mobile-filters-list'>
            <li onClick={() => { setDisplayFilter(0) }}>
              <span><CalendarMonthOutlined /> {displayDate()} </span>
              <ChevronRight />
            </li>
            {isFeatureEnabled(FEATURE.INTERAGENCY) && <li onClick={() => { setDisplayFilter(1) }}>
              <span><Domain /> {displayOwner(selectedFilters.owner)}</span>
              <ChevronRight />
            </li>}
            <li onClick={() => { setDisplayFilter(2) }}>
              <span><RadioButtonChecked /> {displayStatus(selectedFilters.status)}</span>
              <ChevronRight />
            </li>
            <li onClick={() => { setDisplayFilter(3) }}>
              <span><PersonOutline fontSize='medium' /> {displayAuthor(selectedFilters.author)}</span>
              <ChevronRight />
            </li>
          </ul>}
          <div className={`filters-section__item ${displayFilter === 0 ? 'show' : ''}`}>
            <DateInput
              selectsRange={true}
              placeholder="Créé après - Créé avant"
              onChange={handleDateRangeChange}
              onChangeRaw={(e) => e.preventDefault()}
              isClearable={dateAfter && dateBefore}
              onClearableClick={() => onSetSelectedFilters(prev => ({ ...prev, date_after: '', date_before: '' }))}
              // selected={selectedFilters.date_after || ''}
              minDate={dateAfter}
              maxDate={dateBefore}
              expandable={true}
            />
          </div>
          <div className={`filters-section__item ${displayFilter === 1 ? 'show' : ''}`}>
            <Select label="" onChange={handleOwnerChange} selected={selectedFilters.owner} className={`!h-10`}>
              <Option value="all">Toutes les agences</Option>
              {(user.agencies ? user.agencies : [user.agency]).map((agency, index) => (
                <Option key={index} value={manufacturerProperty(agency, 'id')}>{manufacturerProperty(agency, 'name')}</Option>
              ))}
            </Select>
          </div>
          <div className={`filters-section__item ${displayFilter === 2 ? 'show' : ''}`}>
            <Select label="" onChange={handleStatusChange} selected={selectedFilters.status}>
              <Option value="all">Tous les statuts</Option>
              {(isMlsPartner(partner) ? ESIGN_STATUS_FILTER_OPTIONS : VIALINK_SIGNATURE_STATUS_FILTER_OPTIONS).map((status, index) => (
                <Option key={index} value={status}>{SIGNATURE_STATUS_TRANLSATIONS[status]}</Option>
              ))}
            </Select>
          </div>
          <div className={`filters-section__item ${displayFilter === 3 ? 'show' : ''}`}>
            <div className="filters-section__item">
              <Select label="Envoyé par " onChange={handleAuthorChange} selected={selectedFilters.author} className="!h-10">
                <Option value="all">Tous</Option>
                {authors.map((author, index) => (
                  <Option key={index} value={author.id}>
                    {`${(author.firstname === user.firstname && author.lastname === user.lastname) ? 'Moi' : `${author.firstname} ${author.lastname}`} `}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </section>
        <div className='flex gap-2'>
          <button className="ml-2 button button--with-icon button--primary-light rounded-sm refresh-btn text-nowrap" onClick={onRefreshStatuses} >
            <Redo />
            Actualiser les statuts
          </button>
          <ClickAwayListener onClickAway={() => {
            console.log("sorter click away")
            setShowSortDropdown(false)
          }
          }>
            <div className={`filters-section-sort !h-8 select-v2 select-v2--filled position-relative ${showSortDropdown && 'active'}`}>
              <div className="flex lg:px-3 h-8 items-center" onClick={() => setShowSortDropdown(!showSortDropdown)}>
                <div className="flex gap-2">
                  <Sort2 />
                  <span className='sort-label sm-d-none'>
                    {sortOptions[activeSort.activeItem]?.label} {sortOptions[activeSort.activeItem]?.[activeSort[activeSort.activeItem]]}
                  </span>
                </div>
                <span className='chevron-icon'><ChevronRight fontSize='small' /></span>
              </div>

              {showSortDropdown && <div className='select-v2__body_dropdown select-v2__body_dropdown--filled u-custom-scrollbar--3 align-right'>
                {Object.entries(sortOptions).map((option, index) => (
                  <div key={index} className={`dropdown-item`} >
                    <p> {option[1].label} <ChevronRight fontSize={'small'} /> </p>
                    <ul>
                      <li className={`dropdown-item ${activeSort[option[0]] === "asc" ? 'active' : ''}`} onClick={() => onSort(option[0], 'asc')}>
                        {option[1].asc}
                      </li>
                      <li className={`dropdown-item ${activeSort[option[0]] === "desc" ? 'active' : ''}`} onClick={() => onSort(option[0], 'desc')}>
                        {option[1].desc}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>}
            </div>
          </ClickAwayListener>

        </div>
      </div>
    </div>
  )
}

export default SignaturesContentSidebar