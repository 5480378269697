import React, { useState } from 'react'
import { CustomTooltip, Input } from '../../ui_new'
import { InfoCircle } from '../../../assets/icons'

const SenderModalContentAssociate = ({ onReturn, onSubmit }) => {

  const [associateSenderData, setAssociateSenderData] = useState({
    email: '',
  })

  const [associateSenderDataError, setAssociateSenderDataError] = useState(null)
  const [emailError, setEmailError] = useState({ text: '', display: false })

  const handleChangeAssociateSenderData = (e) => {
    const { name, value } = e.target
    setAssociateSenderData({ ...associateSenderData, [name]: value })
  }

  const validateAssociateSenderData = () => {
    setEmailError({ text: '', display: false })
    // const generalError = 'Des informations sont manquantes ou mal renseignées'
    if (associateSenderData.email === '') {
      setEmailError({ text: 'Veuillez renseigner l\'email', display: true })
      return false
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(associateSenderData.email)) {
        setEmailError({ text: 'Veuillez renseigner un email valide', display: true })
        // setAssociateSenderDataError(generalError)
        return false
      }
    }
    setAssociateSenderDataError(null)
    return true
  }

  const handleSubmitAssociate = (e) => {
    e.preventDefault()
    const isValid = validateAssociateSenderData()
    if (!isValid) {
      return
    }
    onSubmit(associateSenderData)
  }


  return (
    <div className=''>
      <div className='folder-modal-v2__heading'>
        <h2 className='modal-title'>Associer un compte expéditeur AR24</h2>
        <ul className='flex flex-row justify-end gap-2'>
          <li>
            <button
              className='btn btn--medium btn--transparent'
              onClick={onReturn}
            >
              Précédent
            </button>
          </li>
          <li>
            <button
              className='btn btn--large'
              onClick={handleSubmitAssociate}
            >
              Soumettre
            </button>
          </li>
        </ul>
      </div>
      <div className='folder-modal-v2__content'>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p>
              Pour associer votre compte expéditeur AR24 à Immo Docs, veuillez renseigner l'e-mail lié à votre compte expéditeur dans le champ ci-dessous :
            </p>
          </li>
          <li>
            <div className={`form--group ${associateSenderDataError ? 'invalid' : ''}`}>
              <label className='form-label' htmlFor='email'>Email</label>
              <div className='form-wrapper'>
                <Input
                  className='form-name mb-6'
                  type='text'
                  placeholder='Email'
                  name='email'
                  value={associateSenderData.email}
                  onChange={handleChangeAssociateSenderData}
                  error={emailError.display && emailError.text}
                />
                <div className='input-tooltip align-end'>
                  <CustomTooltip content={"L'email à renseigner doit être celui qui est associé au compte AR24"}>
                    <div className="info"> <InfoCircle /></div>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </li>
          <li className='modal-list-item'>
            <p>
              Vous recevrez un e-mail vous demandant de valider l'association de votre compte expéditeur AR24 à Immo Docs.
            </p>
          </li>
          {associateSenderDataError && <li className='modal-list-item'>
            <p className='text-error'>
              {associateSenderDataError}
            </p>
          </li>}
        </ul>
      </div>
    </div>
  )
}

export default SenderModalContentAssociate