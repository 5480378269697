import React, { useContext, useState } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'

import { FileAlt2, FolderOpen, RegisteredMail, Signature, Download, InfoCircle, Fave, FolderBorder, Home, Logo, Close, Redo } from '../../assets/icons'
import { FEATURE, isFeatureEnabled, availableOn } from '../../utils'
import { DocumentsContext, UserContext } from '../../context'
import moment from 'moment'
import { NewReleasesOutlined } from '@material-ui/icons'
import { Add, SettingsOutlined, StarRateOutlined } from '@mui/icons-material'
import { AddNewDocument } from '../misc'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const MobileMenu = ({ handleToggleMobileMenu, showDropdown, setShowDropdown, handleToggleDropdown,setShowGiveFeedbackModal }) => {
  const { isMobile } = useWindowDimensions()

  const location = useLocation();
  const { pathname } = location;
  const currentDate = moment().format('DD/MM/YYYY')
  const { siteConfigs } = useContext(UserContext);
  const { globalReload, lastReloadTime } = useContext(DocumentsContext)

  const isNavlinkActive = (match) => (...args) => {
    return args[1].pathname.substring(0, match.length) === match
  }

  const handleGlobalReload = () => {
    globalReload()
  }


  return (
    <div className="mobile-menu u-custom-scrollbar">
      <div className='flex px-4 py-3 justify-between items-center mobile-header'>
        <NavLink to="/" exact={true} onClick={handleToggleMobileMenu}><Logo /></NavLink>
        <div className='cursor-pointer' onClick={handleToggleMobileMenu}>
          <Close />
        </div>
      </div>
      <ul>
        <li onClick={handleToggleMobileMenu}>
          <NavLink to="/" exact={true}><span className="icon-documents"><Home /></span> Tableau de bord</NavLink>
        </li>
        <li onClick={handleToggleMobileMenu}>
          <NavLink to="/documents"><span className="icon-documents"><FileAlt2 /></span> Mes documents</NavLink>
        </li>
        {isFeatureEnabled(FEATURE.STANDARD_TEMPLATES, siteConfigs) && <li onClick={handleToggleMobileMenu}>
          <NavLink to="/standard-templates">
            <span className="icon-templates"><Fave /></span>
            Mes modèles types
          </NavLink>
        </li>}
        <li onClick={handleToggleMobileMenu}>
          <NavLink to="/templates"><span className="icon-templates"><FolderBorder /></span> Modèles</NavLink>
        </li>
        <li onClick={handleToggleMobileMenu}>
          <NavLink to="/signatures"><span className="icon-signatures"><Signature /></span> Signatures</NavLink>
        </li>
        {isFeatureEnabled(FEATURE.AR24, siteConfigs) && <li onClick={handleToggleMobileMenu}>
          <NavLink to="/registered-mail/tracking"><span className="icon-ar24"><RegisteredMail /></span> Lettres recommandées</NavLink>
        </li>}
        <li><div className="hr"></div></li>
        <li onClick={handleToggleMobileMenu}>
          <NavLink to="/what-is-new"><span><NewReleasesOutlined /></span> Nouveautés</NavLink>
        </li>

        <li onClick={handleToggleMobileMenu}>
          <NavLink to="/documentation"><span><Download /></span> Documents utiles</NavLink>
        </li>
        <li onClick={handleToggleMobileMenu}>
          <a href='#' onClick={(e) => { e.preventDefault(); setShowGiveFeedbackModal(true) }}>
            <span>
              <StarRateOutlined /></span>
            Donnez votre avis</a>
        </li>
        <li onClick={handleToggleMobileMenu}>
          <NavLink isActive={isNavlinkActive('/settings')} to="/settings/profile"><span><SettingsOutlined /></span> Paramètres</NavLink>
        </li>
      </ul>
      <div className='mobile-menu__bottom'>
        {isMobile && <AddNewDocument showDropdown={showDropdown} setShowDropdown={setShowDropdown} handleToggleDropdown={handleToggleDropdown} />}
        {/* <button className="link" onClick={handleToggleDropdown}><Add /> Nouveau document</button> */}

        {pathname === '/' && <div className='update'>
          <div className='cursor-pointer' onClick={handleGlobalReload}>
            <Redo />
          </div>
          <span className='text'>Mise à jour</span>
          <span className='date'>{moment(lastReloadTime).format('DD/MM/YYYY')}</span>
        </div>}
      </div>
    </div>
  )
}

export default MobileMenu